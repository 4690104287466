import React, { useEffect, useState, useContext } from "react";
import "../../Css/admin.css";
import { Link, useNavigate } from "react-router-dom";
import { FaUser, FaSignOutAlt, FaSearch } from "react-icons/fa";
import { FiKey, FiUser } from "react-icons/fi";
import { REGISTER_API } from "../../AllApi/addStaffApi";
import profileImage from "../../assets/images/avatars/defaultimage.jpg";
// import { LOGIN_API } from "../../AllApi/loginApi";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { REACT_APP_BASE_PATH } from "../../AllApi/baseUrl";
import UserContext from "../../UserContext";
import { STUDENT_DETAIL_API } from "../../AllApi/studentDetails";
import { TEACHER_API } from "../../AllApi/teacherManageApi";

const AppHeaderDropdown = () => {
  const navigate = useNavigate();
  const user = useContext(UserContext);
  // const activeRole = useContext(UserContext);
  const [profileDetails, setProfileDetails] = useState([]);

  useEffect(() => {
    // console.log(user)
    if (user.userRole === "student") {
      STUDENT_DETAIL_API.GetStudentById(user.currentUser).then((resp) => {
        if (resp.status === 200) {
          setProfileDetails(resp.data[0]);
        }
      });
    } else if (user.userRole === "guardian") {
    } else {
      // console.log(user.currentUser)
      REGISTER_API.GetStaffByStaffId(user.currentUser).then((resp) => {
        // console.log(resp);
        if (resp.status === 200) {
          setProfileDetails(resp.staff[0]);
        }
      });
    }
  }, []);

  const logOut = () => {
    user.setCurrentUser("");
    user.setUserRole("");
    user.setMessage("LogOut successfully");
    localStorage.removeItem("token");
    localStorage.removeItem("user-role");
    localStorage.removeItem("iec-active-user");
    navigate("/login");
  };
  return (
    <div className="row">
      <ToastContainer />
      <div className="col-lg-10">
        {/* <form className="header_search_form">
          <div className="input-group ">
            <input
              type="text"
              defaultValue=""
              name="search_text1"
              id="search_text1"
              className="form-control border-0"
              placeholder="Search By Student Name, Roll Number, Enroll Number, National Id, Local Id Etc."
            />
            <span className="input-group-btn">
              <button
                type="button"
                name="search"
                id="search-btn"
                // onClick="getstudentlist()"
                style={{}}
                className="btn btn-sm border-0 mt-2"
              >
                <FaSearch />
              </button>
            </span>
          </div>
        </form> */}
      </div>
      <div className="col-lg-2">
        <div className="dropdown float-end">
          <img
            src={
              profileDetails.photo
                ? `${REACT_APP_BASE_PATH}/${profileDetails.photo}`
                : profileImage
            }
            className="topuser-image mx-auto d-block"
            role="button"
            alt="User Image"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          />
          {/* <span className="role-name">{profileDetails.role}</span> */}
          <ul className="dropdown-menu dropdown-user menuboxshadow">
            <li>
              <div className="sstopuser">
                <div className="ssuserleft">
                  <img
                    src={
                      profileDetails.photo
                        ? `${REACT_APP_BASE_PATH}/${profileDetails.photo}`
                        : profileImage
                    }
                    alt="User Image"
                  />
                </div>
                <div className="sstopuser-test">
                  <h4 className="text-capitalize">{profileDetails.name}</h4>
                  <h5>{profileDetails.role}</h5>
                </div>
                <div className="divider" />
                <div className="sspass">
                  <Link
                    to={`/admin/staff/profile/${profileDetails.staff_id}`}
                    className="links"
                    data-toggle="tooltip"
                    title=""
                    data-original-title="My Profile"
                  >
                    <FiUser className="me-1" />
                    Profile
                  </Link>
                  <Link
                    to={`/admin/admin/changepass`}
                    className="links"
                    data-toggle="tooltip"
                    title=""
                    data-original-title="My Profile"
                  >
                    <FiKey className="me-1" />
                    Password
                  </Link>
                  <p
                    to="#"
                    className="links"
                    data-toggle="tooltip"
                    title=""
                    data-original-title="Log Out"
                    onClick={logOut}
                  >
                    <FaSignOutAlt className="me-1" />
                    Logout
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AppHeaderDropdown;
